import React from 'react'
import PropTypes from 'prop-types'
import Content from '../Content'

const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className='mw8 center'>
      <article className='cf ph3 ph5-ns pv5'>
        <div className='fn fl-ns'>
          <PageContent content={content} className='avenir lh-copy f4 mt0 fw1' />
        </div>
      </article>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
